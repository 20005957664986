import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Navbar01 from "./components/Navbar01";
import Hero01 from "./components/Hero01";
import About01 from "./components/About01";
import Token01 from "./components/Token01";
import Token02 from "./components/Token02";
import HowTo01 from "./components/HowTo01";
import Join01 from "./components/Join01";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div className="bg-blur-01"></div>
    <div className="bg-blur-02"></div>
    <div className="bg-blur-03"></div>
    <Navbar01 />
    <Hero01 />
    <About01 />

    <Token01 />

    <Join01 />
    <HowTo01 />
  </React.StrictMode>
);
