import "./HowTo01.css";
import picture01 from "../img/cont/community.webp";

function HowTo01() {
  return (
    <div className="HowTo01 section">
      <div className="container">
        <h2>How To Buy ?</h2>

        <div className="HowTo01-box">
          <img src={picture01} alt="picture"></img>

          <div>
            <h3> Get a wallet</h3>
            <p>
              First you need to get a crypto wallet like Phantom or Solflare, to
              be able to interact with the blockchain.
            </p>
          </div>
          <div>
            <h3> Get some SOL</h3>
            <p>
              Next, buy some SOL on an exchange like Coinbase, Binance etc.and
              transfer the funds to your wallet.
            </p>
          </div>
          <div>
            <h3> Buy on DEX</h3>
            <p>
              Finally, go to DEX like Raydium and enter the token address.
              Choose and enter the amount of SOL you want to spend.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowTo01;
