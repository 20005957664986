import "./Navbar01.css";
import picture01 from "../img/logo/favicon.png";

function Navbar01() {
  return (
    <header className="Navbar01">
      <div className="Navbar01-logo">
        <h5>$CULT</h5>
      </div>

      <ul className="Navbar01-links">
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#token">Token</a>
        </li>
        <li>
          <a href="#join">Community</a>
        </li>
        <li>
          <a
            href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=8DJFdAgWWVgTs3vurYyjVhCPZ6TEeiR3KjC18DaSpump&inputMint=sol&outputMint=8DJFdAgWWVgTs3vurYyjVhCPZ6TEeiR3KjC18DaSpump"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn01">BUY NOW</button>
          </a>
        </li>
      </ul>
    </header>
  );
}

export default Navbar01;
