import "./About01.css";

function About01() {
  return (
    <div id="about" className="About01 section">
      <div className="container">
        <div className="About01-box">
          <div>
            <p>
              Throughout history, countless cults and communities have formed,
              each offering its own version of truth, yet none provided a
              unifying purpose. That is when The Cultist emerged—a figure
              destined to unify these fragmented ideologies.
            </p>
          </div>
          <div>
            <p>
              The Cultist declares that all cults are mere reflections of a
              higher cosmic truth, incomplete on their own. Now, under their
              leadership, these scattered groups are drawn together in a single
              mission: the pursuit of ultimate Enlightenment, where the
              boundaries between beliefs dissolve, and the true purpose is
              revealed.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About01;
