import "./Token02.css";

import icon01 from "../img/cont/copy-icon.png";

import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

function Token02() {
  const [textToCopy, setTextToCopy] = useState(
    "8DJFdAgWWVgTs3vurYyjVhCPZ6TEeiR3KjC18DaSpump"
  );
  const [copyStatus, setCopyStatus] = useState(false);

  const handleCopy = () => {
    setCopyStatus(true);
    // Add any additional copy logic here
  };
  return (
    <div id="token" className="Token02 section">
      <div className="container">
        <h2> $CULT </h2>

        <div className="Token02-box">
          <div className="ca">
            <p
              value={textToCopy}
              onClick={() => setTextToCopy(prompt("Enter the text to copy:"))}
              placeholder="0x0000000000"
            >
              {textToCopy}
            </p>
            <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
              <button>
                <img src={icon01} alt="picture"></img>
              </button>
            </CopyToClipboard>
            {copyStatus}
          </div>

          <div className="btnFlex">
            <a
              href="https://www.dextools.io/app/en/solana/pair-explorer/GwbQTqiLLmKzjwx8u1xmMstXGdF2FU9FxBKMj75pExX4?t=1729195519776"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn01">DEXTOOLS</button>
            </a>
            <a
              href="https://solscan.io/token/8DJFdAgWWVgTs3vurYyjVhCPZ6TEeiR3KjC18DaSpump"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn02">SOLSCAN </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Token02;
