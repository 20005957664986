import "./Join01.css";

function Join01() {
  return (
    <div id="join" className="Join01 section">
      <div className="container">
        <div className="Join01-box">
          <h3>Ready to be part of the $CULT revival?</h3>

          <div className="btnFlex">
            <a
              href="https://x.com/cultcoinsol"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn01">X/TWITTER</button>
            </a>
            <a
              href="https://t.me/Cultonsolana"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn02">TELEGRAM</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Join01;
