import "./Hero01.css";
import picture01 from "../img/cont/red hood.webp";

function Hero01() {
  return (
    <div className="Hero01 section">
      <div className="container">
        <div className="Hero01-box">
          <div>
            <h1>WE ARE THE $CULT </h1>
            <h3>Cult CTO - A New Era Begins.</h3>
            <a
              href="https://t.me/Cultonsolana"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn01">JOIN THE $CULT</button>
            </a>
          </div>
          <img src={picture01} alt="picture"></img>
        </div>
      </div>
    </div>
  );
}

export default Hero01;
